$(document).ready(function(){

    var cE = (function() {
        if ('ontouchstart' in document.documentElement === true)
            return 'touchend';
        else
            return 'click';
    })();

    var dw = $(window).width();

    if(dw < 768) {
        setInterval(function () {
            if (didScroll) {
                if($('body').hasClass('menu-on')) {
                    return;
                }
                hasScrolled();
                sticky_relocate();
                didScroll = false;
            };
        }, 250);
        $('.nav').removeClass('hidden-mobile-load');

        if($('.top-coupons-list').length)
        {
            var elp = $('.top-coupons-list');
            var el = $('.top-coupons-container');
            var w = el.outerWidth();
            var s = elp[0].scrollWidth;

            if(s>w) {
                el.addClass('scrollable');
            }
        }

    }

    if(dw < 992) {
       initSlick();
    }

    if(dw < 768) {
        initSlickBox();
    }

    $(window).resize(function(){
        var $wW = $(window).width();
        if ($wW < 992) {
            initSlick();
        }
        else {
            destroySlick('.index-main-banners');
        }

        if ($wW < 768) {
            setInterval(function () {
                if (didScroll) {
                    if($('body').hasClass('menu-on')) {
                        return;
                    }
                    hasScrolled();
                    sticky_relocate();
                    didScroll = false;
                };
            }, 250);
            initSlickBox();
            if(!$('.nav').hasClass('is-on')) {
                $('.nav').removeClass('hidden-mobile-load');
            }

            if($('.top-coupons-list').length)
            {
                var elp = $('.top-coupons-list');
                var el = $('.top-coupons-container');
                var w = el.outerWidth();
                var s = elp[0].scrollWidth;

                if(s>w) {
                    el.addClass('scrollable');
                }
                else {
                    el.removeClass('scrollable');
                }
            }
        }
        else {
            destroySlick('.box-side--coupons');
            closeNav();
            $('.nav').addClass('hidden-mobile-load');
            if($('.top-coupons-list').length)
            {
                var el = $('.top-coupons-container');
                    el.removeClass('scrollable');
            }
        }
    });

    function initSlick() {
        $('.index-main-banners').not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            speed: 300,
            autoplay: true,
            arrow: true,
            adaptiveHeight: true
        });
    }

    function initSlickBox() {
        $('.box-side--coupons').not('.slick-initialized').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            slidesToShow: 2.3,
            ofset: 20
        });
    }


    function destroySlick(c) {
        $(c+'.slick-initialized').slick('unslick');
    }
    function resizeSlick(d){
        $(d+'.slick-initialized').slick('resize');
    }

    $('.mobile-menu-toggle').on('click', function (event) {
        if($('body').hasClass('menu-on')) {
            $('.index-main-banners').slick('slickPlay');
        }
        else {
            $('.index-main-banners').slick('slickPause');
        }
        var popup = $('.nav');
        var offsetY = window.pageYOffset;
        if(popup.hasClass('is-on')) {
            closeNav();
        }
        else {
            $('body').addClass('menu-on');
            $('.nav').addClass('is-on');
            setTimeout(function(){
                $('.nav-close').addClass('is-visible');
                }, 500);
            $('body').css({
                'top': -offsetY + 'px'
            });
        }
        event.stopPropagation();
    });


    $('.nav-close').on('click', function (event) {
        if($('body').hasClass('menu-on')) {
            $('.index-main-banners').slick('slickPlay');
        }
        else {
            $('.index-main-banners').slick('slickPause');
        }
        closeNav();
    });

    $(document).on(cE, function (e) {
        var popup = $('.nav');
        var elem = $('.mobile-menu-toggle');
        if (elem.is(event.target))
        {
            return;
        }
        if(popup.hasClass('is-on')) {
            if (!popup.is(e.target) && popup.has(e.target).length === 0)
            {
                closeNav();
            }
        }
    });

    function closeNav() {
        var y = $('body').offset().top;
        $('body').removeClass('menu-on');
        $('.nav').removeClass('is-on');
        $('.nav-close').removeClass('is-visible');
        window.scrollTo({
            top: -y
        });
    }


    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.header').outerHeight();

    $(window).scroll(function () {
        didScroll = true;
    });


    function sticky_relocate() {
        var window_top = $(window).scrollTop();
        var stickit = $('.header-stickit');
        var sticked = $('.header');
        var headerht = stickit.height(sticked.outerHeight());
        if (stickit.length)
        {
            var div_top = sticked.offset().top;

            if(window_top < 50) {
                sticked.removeClass('stickit');
                sticked.removeClass('nav-up').removeClass('nav-down');
                stickit.height(0);
            }
            else if (window_top > div_top - 200) {
                sticked.addClass('stickit');
                stickit.height(sticked.outerHeight());
            } else {
                sticked.removeClass('stickit');
                sticked.removeClass('nav-up').removeClass('nav-down');
                stickit.height(0);
            }
        }
    }

    function hasScrolled() {
        var st = $(window).scrollTop();

        var sticked = $('.header');
        var stickit = $('.header-stickit');

        if (stickit.length)
        {
            var div_top = stickit.offset().top;
            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta)
                return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.

            if (sticked.hasClass('stickit')) {
                if (st > lastScrollTop && st > div_top + 250) {
                    // Scroll Down
                    sticked.removeClass('nav-down').addClass('nav-up');
                } else {
                    // Scroll Up
                    if (st + $(window).height() < $(document).height()) {
                        sticked.removeClass('nav-up').addClass('nav-down');
                    }
                }
            }
            lastScrollTop = st;
        }
    }

    $('.nav-category').on(cE, function (e) {
        var dw = $(window).width();
        $(window).resize(function(){
            dW = $(window).width();
        });

        if($(this).hasClass('active')) {
            if(dw < 768) {
                $(this).removeClass('active');
            }
            else {
                $(this).toggleClass('is-collapsed');
            }
        }
        else {
            if(dw < 768) {
                $(this).addClass('active');
            }
            else {
                $(this).toggleClass('is-collapsed');
            }
        }
    });

    $('.nav-sub-categories').on(cE, function (e) {
        e.stopPropagation();
    });

    $('.f-link--categories').on(cE, function (e) {
        var dw = $(window).width();
        $(window).resize(function(){
            dW = $(window).width();
        });

         var offsetY = window.pageYOffset;
        $('body').addClass('menu-on');
        $('.nav').addClass('is-on');
        $('.header').addClass('stickit nav-down').removeClass('nav-up');
        setTimeout(function(){
            $('.nav-close').addClass('is-visible');
        }, 500);
        if(!$('.nav-category').hasClass('active')) {
            $('.nav-category').addClass('is-collapsed');
        }
        $('body').css({
            'top': -offsetY + 'px'
        });
        e.stopPropagation();
        e.preventDefault();
    });

    $('.pageNumber').on('keyup input', function () {
        var pIn = $(this);
        var pInVal = pIn.val();
        var cInVal = parseInt(pIn.attr('data-value'));

        if (pInVal < parseInt(pIn.attr('min')) || pInVal > parseInt(pIn.attr('max')) || pInVal == parseInt(cInVal) || !pInVal.match(/\d+/)) {
            $(this).parent().find('button').prop('disabled', true);
        }
        else {
            $(this).parent().find('button').prop('disabled', false);
        }
    });

    $('.pageNumber').on('blur', function () {
        var pageInput = $(this);
        var pageInputValue = pageInput.val();
        var currentInputValue = parseInt(pageInput.attr('data-value'));

        if (pageInputValue < parseInt(pageInput.attr('min')) || pageInputValue > parseInt(pageInput.attr('max')) || !pageInputValue.match(/\d+/)) {
            $(this).val(currentInputValue);
        }
    });

// Setup isScrolling variable
    var isScrollingTop;
// Listen for scroll events
    $('.top-coupons-list').on('scroll', function ( event ) {
        var pos = $(this).scrollLeft();
        var el = $('.top-coupons-container');
        var w = $(this).outerWidth();
        var s = $(this)[0].scrollWidth;

        if(s>w) {
            el.addClass('scrollable');
        }
        // Clear our timeout throughout the scroll
        clearTimeout( isScrollingTop );
        // Set a timeout to run after scrolling ends

        el.addClass('scrolling');
        el.removeClass('scrolled-right');

        isScrollingTop = setTimeout(function() {
            // Run the callback
            if(pos + w >= s) {
                el.addClass('scrolled-right');
                el.removeClass('scrolling');
            }
            if(pos == 0) {
                el.removeClass('scrolling');
            }
        }, 250);
    });

    var animationEnd = (function(el) {
        var animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'mozAnimationEnd',
            WebkitAnimation: 'webkitAnimationEnd',
        };

        for (var t in animations) {
            if (el.style[t] !== undefined) {
                return animations[t];
            }
        }
    })(document.createElement('div'));

    $('.mobile-search-toggle').on('click', function () {

        var runonce;
        var el = $('.nav-search');


        if(el.hasClass('is-on')) {
            el.slideUp(250);
            el.removeClass('is-on');
            /*el.removeClass('fadeInDown delay-250s').addClass('fadeOutUp quick');
            el.one(animationEnd, function() {
                el.slideUp(250);
                el.removeClass('is-on');
            });*/
            /*runonce=setInterval(slideUpSearch, 400);
            function slideUpSearch()
            {
                el.slideUp(250);
                el.removeClass('is-on');
                clearInterval(runonce);
            }*/

           /* el.one(animationEnd, function() {
                el.slideUp(250);
                el.removeClass('is-on');
            });*/
        }
        else {
            el.slideDown(250);
            el.addClass('is-on');
            /*el.removeClass('fadeOutUp').addClass('animated fadeInDown delay-250s');*/
        }

    });
});

function goPages(e){
    var page = parseInt($(e).parent().find('input').val());
    var loc = $(e).parent('form').attr('action');
    location.href = loc + page;
}

function goPagesForm(e){
    e.preventDefault();
    var page = parseInt($(e.target).find('input').val());
    var loc = $(e.target).attr('action');
    console.log(page + 'p');
    if(!isNaN(page)) {
        location.href = loc + page;
    }
}
